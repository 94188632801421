/** @jsx jsx */
import React from 'react'
import { jsx, useThemeUI, Button } from 'theme-ui'

const ColorModeButton = () => {
  const context = useThemeUI()
  const { colorMode, setColorMode } = context
  const colorModeName = colorMode.replace(/^\w/, c => c.toUpperCase())
  return (
    <Button
      variant="colorMode"
      sx={{
        cursor: 'pointer',
        width: 'auto',
        height: 'auto',
      }}
      aria-label="Toggle color mode"
      onClick={e => {
        e.preventDefault()
        const next = colorMode === 'dark' ? 'light' : 'dark'
        setColorMode(next)
      }}
    >
      {colorModeName === 'Default' ? 'Light' : colorModeName}
    </Button>
  )
}

export default ColorModeButton
