/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { jsx, Text, Image, IconButton, MenuButton } from 'theme-ui'
import ColorModeButton from './colorModeButton'

const Header = ({ logo, headerMessage, onMenuOpen }) => {
  return (
    <header
      sx={{
        variant: 'styles.header',
      }}
    >
      <div
        sx={{
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'primary',
          color: '#fff',
          p: 2,
        }}
      >
        <div sx={{ mx: 'auto' }} />
        <Text variant="headerMsg" sx={{ textAlign: 'center' }}>
          {headerMessage}
        </Text>
        <div sx={{ mx: 'auto' }} />
      </div>
      <div
        sx={{
          py: 2,
          px: 3,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: ['center', 'center', 'flex-start', 'flex-start'],
          maxWidth: 1440,
          mx: 'auto',
        }}
      >
        <div
          sx={{ width: 55, display: ['inherit', 'inherit', 'none', 'none'] }}
        >
          <MenuButton onClick={onMenuOpen} />
        </div>
        <div
          sx={{ mx: 'auto', display: ['inherit', 'inherit', 'none', 'none'] }}
        />
        <Image src={logo.src} sx={{ ml: [0, 0, 2, 2] }} />
        <div sx={{ mx: 'auto' }} />
        <ColorModeButton />
      </div>
    </header>
  )
}

Header.propTypes = {
  logo: PropTypes.shape({
    src: PropTypes.string,
  }),
  headerMessage: PropTypes.string,
  onMenuOpen: PropTypes.func,
}

export default Header
