/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { jsx, Styled, Grid, Box, Container } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import Drawer from 'rc-drawer'

import Sidebar from './sidebar'
import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  const { allSettings } = useStaticQuery(graphql`
    {
      allSettings {
        nodes {
          header_message
          site_title
          logo {
            src
            title
          }
        }
      }
    }
  `)
  const [showMenu, setShowMenu] = useState(false)

  const handleMenuClose = () => {
    setShowMenu(false)
  }

  const handleMenuOpen = () => {
    setShowMenu(!showMenu)
  }

  return (
    <Styled.root
      sx={{
        position: 'relative',
      }}
    >
      <Drawer
        width="200px"
        handler={false}
        open={showMenu}
        onClose={handleMenuClose}
      >
        <Sidebar />
      </Drawer>
      <Header
        headerMessage={allSettings.nodes[0].header_message}
        logo={allSettings.nodes[0].logo}
        onMenuOpen={handleMenuOpen}
      />
      <div
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'baseline',
          maxWidth: 1440,
          mx: 'auto',
          minHeight: 'calc(100vh - 172px)',
          px: 3,
        }}
      >
        <Grid
          gap={1}
          columns={[
            [1, '8fr'],
            [1, '8fr'],
            [2, '1.5fr 8fr'],
            [2, '1.5fr 8fr'],
          ]}
        >
          <Box
            sx={{
              display: ['none', 'none', 'block', 'block'],
            }}
          >
            <Sidebar />
          </Box>
          <Box>
            <Container>{children}</Container>
          </Box>
        </Grid>
      </div>
      <Footer />
    </Styled.root>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
