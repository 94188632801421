/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { jsx, Text } from 'theme-ui'

const Footer = () => {
  return (
    <footer
      sx={{
        variant: 'styles.footer',
        height: 51,
        mt: 4,
        borderTop: '1px solid',
        borderColor: 'muted',
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          mx: 'auto',
          maxWidth: 1440,
        }}
      >
        <div sx={{ mx: 'auto' }} />
        <Text sx={{ p: 2 }} variant="body2">
          Copyright © 2020 UXStash. All rights reserved.{' '}
        </Text>
        <div sx={{ mx: 'auto' }} />
      </div>
    </footer>
  )
}

export default Footer
