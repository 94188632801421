/** @jsx jsx */
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Icon } from '@mdi/react'
import { jsx, Flex, Grid, Box, Text } from 'theme-ui'

const styles = {
  navGrid: {
    pt: 4,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    fontSize: 2,
    fontWeight: 'medium',
    '&.active': {
      color: 'primary',
    },
  },
}

const Sidebar = () => {
  const camelize = str => {
    const arr = str.split('-')
    const capital = arr.map((item, index) =>
      index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item
    )
    return capital.join('')
  }
  const { allSettings, cms } = useStaticQuery(graphql`
    {
      allSettings {
        nodes {
          header_message
          categories {
            id
          }
        }
      }
      cms {
        categories(active: true) {
          id
          name
          color
          slug
          icon_name
        }
      }
    }
  `)
  const categories = {}
  cms.categories.forEach(c => (categories[c.id] = c))
  return (
    <Grid gap={3} sx={styles.navGrid}>
      {allSettings.nodes[0].categories.map(c => {
        const category = categories[c.id]
        if (category) {
          return (
            <Link
              key={category.id}
              to={`/${category.slug}`}
              sx={styles.link}
              activeClassName="active"
              aria-label={category.name}
            >
              <Flex>
                <Box
                  px={2}
                  pr={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon
                    path={
                      require('@mdi/js')[camelize(`mdi-${category.icon_name}`)]
                    }
                    size="20px"
                  />
                </Box>
                <Box>{category.name}</Box>
              </Flex>
            </Link>
          )
        }
      })}
    </Grid>
  )
}

export default Sidebar
